import React, { useState, useEffect } from 'react';
// eslint-disable-next-line 
import { Amplify } from 'aws-amplify';
import { signIn, fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import logo from './fieglogo.png';
import logo1 from './softwareone.png';
import logo2 from './proservelogo.png';

const LoginScreen = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [errorMessage, setErrorMessage] = useState('');

    function findIdTokenInLocalStorage() {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.includes('idToken')) {
          return { key, value: localStorage.getItem(key) };
        }
      }
      console.log('Nenhuma chave contendo "idToken" foi encontrada.');
      return null;
    }

    const token = findIdTokenInLocalStorage();

    useEffect(() => {
      if (token) {
      checkUser();
      }
// eslint-disable-next-line
    }, []);

    const checkUser = async () => {
      try {
        const user = await fetchAuthSession({ forceRefresh: true });
        console.log('Usuário já autenticado:', user);
        // Usuário já está logado, você pode redirecioná-lo ou executar outras ações
        navigate('/download');
      } catch (err) {
        console.log('Nenhum usuário autenticado');
      }
    };



    const handleLogin = async () => {
      try {
        // eslint-disable-next-line
        const session = await signIn({ username, password, })
        navigate('/download');
      } catch (error) {
        if (error.code === 'UserAlreadyAuthenticatedException') {
          navigate('/download');
          console.log('Usuário já está autenticado.')
        } else {
          console.error('Erro ao fazer login:', error);
          setErrorMessage(error.message);
        }
      }
    };

  return (
    <div style={{ backgroundColor: 'white', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '400px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <img src={logo} alt="Logo" style={{ position: 'absolute', top: 20, left: 20, height: '50px' }}/>
          <div>
            <img src={logo1} alt="Logo 1" style={{ position: 'absolute', top: 20, right: 50, height: '50px'}}  />
            <img src={logo2} alt="Logo 2" style={{ position: 'absolute', top: 100, right: 20, height: '65px' }} />
          </div>
        </div>
        <input
          type="text"
          placeholder="Usuário"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
        />
        <input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ width: '100%', padding: '10px', marginBottom: '20px' }}
        />
        <button
          onClick={handleLogin}
          style={{ backgroundColor: 'blue', color: 'white', width: '50%', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} >
          Entrar
        </button>
      </div>
    </div>
  );
};

export default LoginScreen;