import React, { useState } from 'react';
import axios from 'axios';
import logo from './fieglogo.png';
import logo1 from './softwareone.png';
import logo2 from './proservelogo.png';


// Componente de Upload
const UploadScreen = () => {
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState('');
  
  function findIdTokenInLocalStorage() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes('idToken')) {
        return { key, value: localStorage.getItem(key) };
      }
    }
    console.log('Nenhuma chave contendo "idToken" foi encontrada.');
    return null;
  }
  
  // Chame a função
  const token = findIdTokenInLocalStorage();

  const currentTimestamp = Date.now();
    
  const handleFileUpload = async () => {
    if (!token) {
      // Redirecionar para a tela de login
      window.location.href = '/login';
      return;
    }

    if (fileType === 'pdf') {
      try {
        const response = 
      await axios.post(process.env.REACT_APP_API_URL+'/dev/admin/presigned-document-upload', 
        { 
            entidade: "SESI",
            idCurso: "127",
            idUnidade: "1",
            idComponente: currentTimestamp,
            titulo: "Calculo 1 - Aula 1",
            tipoDocumento: fileType,
            tipoMedia: "TEXTO",
            plataformaLMS: "EAD",
            idInstrutor: 3,
            nomeInstrutor: "Lucas",
            emailInstrutor: "professor@fieg.com",
            versaoMetadados: "1.0"
             
    
        }, {
        headers: {
          Authorization: `Bearer ${token.value}`,
          'x-api-key': process.env.REACT_APP_API_KEY
        },
      });
      const uploadUrl = response.data.presignedUrlUpload;

      // Fazer o upload do arquivo para o S3 usando a URL assinada
      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      // Exibir o popup de sucesso
      alert('Arquivo enviado com sucesso!\nO resultado será enviado para o seu e-mail assim que estiver concluído.');
      window.location.reload();
    } catch (error) {
      console.error('Erro no upload:', error);
    }
    } else if (fileType === 'mp4') {
      try {
        const response = 
      await axios.post(process.env.REACT_APP_API_URL+'/dev/admin/presigned-video-upload', 
        { 
            entidade: "SESI",
            idCurso: "127",
            idUnidade: "1",
            idComponente: currentTimestamp,
            titulo: "Calculo 1 - Aula 1",
            tipoDocumento: fileType,
            tipoMedia: "VIDEO",
            plataformaLMS: "EAD",
            idInstrutor: 3,
            nomeInstrutor: "Lucas",
            emailInstrutor: "professor@fieg.com",
            versaoMetadados: "1.0",
            multipart_upload_parts: 1
             
    
        }, {
        headers: {
          Authorization: `Bearer ${token.value}`,
          'x-api-key': process.env.REACT_APP_API_KEY
        },
      });
      const uploadUrl = response.data.presignedUrlUpload;

      // Fazer o upload do arquivo para o S3 usando a URL assinada
      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      // Exibir o popup de sucesso
      alert('Arquivo enviado com sucesso!\nO resultado será enviado para o seu e-mail assim que estiver concluído.');
      window.location.reload();
    } catch (error) {
      console.error('Erro no upload:', error);
      window.location.href = '/login';
    }
    }
    
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileType(selectedFile.type.includes('mp4') ? 'mp4' : 'pdf');
  };

  const handleChange = (event) => {
    setFileType(event.target.value);
  };

  return (
    <div style={{ backgroundColor: 'white', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        <img src={logo} alt="Logo" style={{ position: 'absolute', top: 20, left: 20, height: '50px' }} />
        <img src={logo1} alt="Logo 1" style={{ position: 'absolute', top: 20, right: 50, height: '50px'}} />
        <img src={logo2} alt="Logo 2" style={{ position: 'absolute', top: 100, right: 20, height: '65px' }} />
        <h2 style={{width: '100%', padding: '10px', border: 'none', marginLeft: '-10%'}}  >Tipo de conteúdo</h2>
        <div>
          <select id="dropdown" name="dropdown"  style={{width: '50%', padding: '10px', marginLeft: '-8%'}} value={fileType} onChange={handleChange}>
            <option value="mp4">Vídeo</option>
            <option value="pdf">Arquivo PDF</option>
          </select>
          <input type="file" style={{width: '40%',   marginBottom: 25, marginLeft: 80}} accept={fileType === 'mp4' ? 'video' : 'application/pdf'} onChange={handleFileChange}/>
        </div>
        <div style={{ marginLeft: '-37px', marginTop: 20, marginBottom: 20,  padding: '10px', border: '1px solid ', width: '100%' }}>{file ? file.name : 'Nenhum arquivo selecionado'}</div>
        <button 
        style={{ backgroundColor: 'blue', color: 'white', width: '50%', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} 
         onClick={handleFileUpload}>Enviar</button>
      </div>
    </div>
  );
};

export default function App() {
  return (
    <div>
      <UploadScreen />
    </div>
  );
}