import React, { useState } from 'react';
import axios from 'axios';
import logo from './fieglogo.png';
import logo1 from './softwareone.png';
import logo2 from './proservelogo.png';
import './DownloadScreen.css';

// Componente de Upload
const DownloadScreen = () => {
  const [isSignedCookiesValidated, setIsSignedCookiesValidated] = useState(false);
  const [isSignedCookiesValidatedVideos, setIsSignedCookiesValidatedVideos] = useState(false);
  
  function findIdTokenInLocalStorage() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes('idToken')) {
        return { key, value: localStorage.getItem(key) };
      }
    }
    console.log('Nenhuma chave contendo "idToken" foi encontrada.');
    return null;
  }
  
  // Chame a função
  const token = findIdTokenInLocalStorage();

  const handleFileUpload = async () => {
    const response = 
      await axios.get(process.env.REACT_APP_API_URL+'/admin/documents/SESI/111/123', 
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        withCredentials: true,
      });

    if (!token) {
      // Redirecionar para a tela de login
      window.location.href = '/login';
      return;
    }
    setIsSignedCookiesValidated(true)
  };

  const handleButtonClick = async(fileName) => {
    window.open(`https://assets.fieg.awsvs.people.aws.dev/documents/SESI/111/123/54130045-fca1-46db-af11-2f0b8a21d5b0/${fileName}`);
  }

  const handleButtonClickVideo = async(fileName) => {
    window.open(`https://assets.fieg.awsvs.people.aws.dev/videos/SESI/111/123/ed45ee2c-7740-46bc-b61c-f4602d861f51/${fileName}`);
  }

  const handleFileUploadVideo = async () => {
    const response = 
      await axios.get(process.env.REACT_APP_API_URL+'/admin/videos/SESI/111/123', 
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        withCredentials: true,
      });

      console.log("response", response);
    if (!token) {
      // Redirecionar para a tela de login
      window.location.href = '/login';
      return;
    }
    setIsSignedCookiesValidatedVideos(true)
  };

  return (
    <div style={{ backgroundColor: 'white', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        <img src={logo} alt="Logo" style={{ position: 'absolute', top: 20, left: 20, height: '50px' }} />
        <img src={logo1} alt="Logo 1" style={{ position: 'absolute', top: 20, right: 50, height: '50px'}} />
        <img src={logo2} alt="Logo 2" style={{ position: 'absolute', top: 100, right: 20, height: '65px' }} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <button style={{ backgroundColor: 'blue', color: 'white', width: '50%', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} 
            onClick={handleFileUpload}>API GET SIGNED COOKIES DOCUMENTS</button>
            <button className={!isSignedCookiesValidated ? 'disabled-button' : ''} disabled={!isSignedCookiesValidated} style={{ backgroundColor: 'blue', color: 'white', width: '50%', marginTop: '10px', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} 
            onClick={() => handleButtonClick('audio.webm')}>AUDIO</button>
            <button className={!isSignedCookiesValidated ? 'disabled-button' : ''} disabled={!isSignedCookiesValidated} style={{ backgroundColor: 'blue', color: 'white', width: '50%', marginTop: '10px', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} 
            onClick={() => handleButtonClick('summary.txt')}>RESUMO</button>
            <button className={!isSignedCookiesValidated ? 'disabled-button' : ''} disabled={!isSignedCookiesValidated} style={{ backgroundColor: 'blue', color: 'white', width: '50%', marginTop: '10px', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} 
            onClick={() => handleButtonClick('transcription.txt')}>TRANSCRIÇÃO</button>
          </div>
          <div>
            <button style={{ backgroundColor: 'blue', color: 'white', width: '50%', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} 
            onClick={handleFileUploadVideo}>API GET SIGNED COOKIES VIDEOS</button>
            <button className={!isSignedCookiesValidatedVideos ? 'disabled-button' : ''} disabled={!isSignedCookiesValidatedVideos} style={{ backgroundColor: 'blue', color: 'white', width: '50%', marginTop: '10px', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} 
            onClick={() => handleButtonClickVideo('summary.txt')}>RESUMO</button>
            <button className={!isSignedCookiesValidatedVideos ? 'disabled-button' : ''} disabled={!isSignedCookiesValidatedVideos} style={{ backgroundColor: 'blue', color: 'white', width: '50%', marginTop: '10px', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} 
            onClick={() => handleButtonClickVideo('transcription.vtt')}>TRANSCRIÇÃO VTT</button>
            <button className={!isSignedCookiesValidatedVideos ? 'disabled-button' : ''} disabled={!isSignedCookiesValidatedVideos} style={{ backgroundColor: 'blue', color: 'white', width: '50%', marginTop: '10px', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} 
            onClick={() => handleButtonClickVideo('transcription.json')}>TRANSCRIÇÃO JSON</button>
            <button className={!isSignedCookiesValidatedVideos ? 'disabled-button' : ''} disabled={!isSignedCookiesValidatedVideos} style={{ backgroundColor: 'blue', color: 'white', width: '50%', marginTop: '10px', padding: '10px', border: 'none', cursor: 'pointer', marginLeft: '25%'  }} 
            onClick={() => handleButtonClickVideo('transcription.srt')}>TRANSCRIÇÃO SRT</button>
          </div>
        </div>
        
      </div>
        
    </div>
  );
}

export default function App() {
  return (
    <div>
      <DownloadScreen />
    </div>
  );
}